import Link from "@/components/ui/Link/Link";

const Navigation = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link isNavLink to="/">
            Home
          </Link>
        </li>
        <li>
          <Link isNavLink to="/about">
            About
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
