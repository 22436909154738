import { createBrowserRouter } from "react-router-dom";

// Components
import Layout from "@/components/Layout/Layout";
import { Suspense } from "react";
import GlobalLoader from "@/components/GlobalLoader/GlobalLoader";

// Pages
import { LazyAboutPage, LazyHomePage, LazyPage404, LazyPostPage } from "@/pages/LazyPages";

const ROUTES = {
  homePage: {
    path: "/",
  },
  aboutPage: {
    path: "about",
  },
  post: {
    path: "post/:id",
  },
  // gamePlayPage: {
  //   path: "game",
  // },
  // drawItemPage: {
  //   path: "draw/:id",
  // },
};

const getPage = LazyPage => (
  <Suspense fallback={<GlobalLoader />}>
    <LazyPage />
  </Suspense>
);

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <LazyPage404 />,
    children: [
      {
        path: ROUTES.homePage.path,
        element: getPage(LazyHomePage),
      },
      {
        path: ROUTES.aboutPage.path,
        element: getPage(LazyAboutPage),
      },
      {
        path: ROUTES.post.path,
        element: getPage(LazyPostPage),
      },
    ],
  },
]);
